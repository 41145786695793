@tailwind base;
@tailwind components;
@tailwind utilities; 
@font-face {
  font-family: 'Nexa Extra Light';
  src: url('./assets/fonts/Nexa-ExtraLight.ttf') format('truetype');
}

@font-face {
  font-family: 'Nexa Heavy';
  src: url('./assets/fonts/Nexa-Heavy.ttf') format('truetype');
}

@font-face {
  font-family: 'Mentalist';
  src: url('./assets/fonts/Mentalist.otf') format('opentype');
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.slick-slider .slick-prev:before, .slick-slider .slick-next:before{
  display: none;
}

.slick-slider .slick-track > div > div > div{
  background-color: transparent !important;
}
html {
  scroll-behavior: smooth;
} 
